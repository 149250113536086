import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion – Legal Mentions & Informations',
  keywords: [],
  description:
    'Find out your customers’ emotions thanks to Q°emotion. Please read all our legal informations before using our SaaS Solution',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "legalInformation" }, lang: { eq: "en" } }
    ) {
      rawMarkdownBody
    }
  }
`;
